import React, { useState } from 'react';
import { FaXTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa6';
import './App.css';
import { log } from 'console';

type UrlStatus = 'suspicious' | 'legitimate';

type Feature = {
  name: string;
  value: string | number | boolean;
};

function App() {
  const [url, setUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [language, setLanguage] = useState<'en' | 'ar'>('ar');
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [urlStatus, setUrlStatus] = useState<'suspicious' | 'legitimate'>('suspicious');
  const [features, setFeatures] = useState<Feature[]>([]);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSubmissionSuccess(false);
    setIsLoading(true);
    
    try {
      const response = await fetch(process.env.REACT_APP_API_URL!, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-API-KEY': process.env.REACT_APP_API_KEY!
        },
        body: JSON.stringify({ 
          url,
          label: urlStatus === 'suspicious' ? 0 : 1
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Unknown error occurred');
      }

      setSubmissionSuccess(true);
      setUrl('');
    } catch (err) {
      console.log(err)
      setError(language === 'ar' ? 'الرابط غير صحيح' : 'Invalid URL')
      ;
    } finally {
      setIsLoading(false);
    }
  };

  return (
  
    <div className="app" dir={language === 'ar' ? 'rtl' : 'ltr'}>
      <div className="language-switcher">
          <button onClick={() => setLanguage('ar')}>العربية</button>
          <button onClick={() => setLanguage('en')}>English</button>
    </div>
      

      <main>
        <div className="container">
        <header>
        <h1>مشبوه</h1>
        <h3> Mashbooh</h3>
      </header>
          <section className="about">
            {language === 'ar' ? (
              <p>
   
   "مشبوه" هو مشروع نموذج ذكاء اصطناعي عربي غير تجاري يهدف إلى التعرّف على الروابط المشبوهة وتعزيز أمنك المعلوماتي على الإنترنت. نعمل حاليًا على جمع المواقع المشبوهة والشرعية لتدريب النموذج، ونقدّر مساهمتك في هذه العملية.

تنويه: جميع المشاركات تخضع لمراجعة بشرية لضمان جودة البيانات المدخلة.
   </p>
            ) : (
              <p>
               "Mashbooh" is a non-commercial Arabic AI project designed to detect suspicious links and enhance your online information security. We are currently collecting both suspicious and legitimate websites to train the model, and we appreciate your contribution to this effort.

Note: All submissions undergo human review to ensure the quality of the data provided. 
              </p>
            )}
          </section>

          <form onSubmit={handleSubmit}>
            <input
              type="url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder={language === 'ar' ? 'ادخل الرابط هنا' : 'Enter URL here'}
              required
            />
            <div className="url-status">
              <label>
                <input
                  type="radio"
                  name="urlStatus"
                  value="suspicious"
                  checked={urlStatus === 'suspicious'}
                  onChange={() => setUrlStatus('suspicious')}
                />
                {language === 'ar' ? 'مشبوه' : 'Suspicious'}
              </label>
              <label>
                <input
                  type="radio"
                  name="urlStatus"
                  value="legitimate"
                  checked={urlStatus === 'legitimate'}
                  onChange={() => setUrlStatus('legitimate')}
                />
                {language === 'ar' ? 'شرعي' : 'Legitimate'}
              </label>
            </div>
            <button type="submit" disabled={isLoading}>
              {isLoading ? (language === 'ar' ? 'جارٍ الإرسال...' : 'Submitting...') : (language === 'ar' ? 'إرسال' : 'Submit')}
            </button>
          </form>

          {error && <div className="error">{error}</div>}

          {submissionSuccess && (
            <div className="success-message">
              {language === 'ar' ? 'شكرًا لمساهمتك! تم إرسال الرابط بنجاح.' : 'Thank you for your contribution! URL submitted successfully.'}
            </div>
          )}
        </div>
      </main>

      <footer>
        <div className="social-links">
          <a href="https://x.com/hasalanzi" target="_blank" rel="noopener noreferrer"><FaXTwitter /></a>
          <a href="https://linkedin.com/in/hassanalanzi" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
          <a href="#" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
        </div>
        {/* <p>© 2025 مشبوه. جميع الحقوق محفوظة.</p> */}
      </footer>
    </div>
  );
}

export default App;
